<template>
  <v-container class="pa-5 mt-4">
    <v-row>
      <v-col cols="12" sm="2"> </v-col>
      <v-col cols="12" sm="6">
        <v-select
          shaped
          filled
          :items="['SMS', 'Push Notification']"
          label="Type of message"
          required
          v-model="MessageType"
        ></v-select>
        <v-select
          v-if="MessageType == 'SMS'"
          shaped
          filled
          :items="DeliverTo"
          item-text="name"
          item-value="value"
          label="Deliver To"
          required
          v-model="deliverTO"
        ></v-select>
        <v-select
          v-else
          shaped
          filled
          :items="DeliverTo2"
          item-text="name"
          item-value="value"
          label="Deliver To"
          required
          v-model="deliverTO"
        ></v-select>
        <v-text-field
          shaped
          filled
          v-if="MessageType != 'SMS'"
          label="Title"
          v-model="title"
          hint="#e.g Parcel arrival"
        ></v-text-field>
        <v-textarea
          filled
          v-model="message"
          auto-grow
          label="Enter Message"
          rows="4"
          row-height="30"
          shaped
        ></v-textarea>
        <div class="text-end">
          <v-btn
            @click="SendPushNotification"
            v-if="MessageType != 'SMS'"
            rounded
            color="pink"
            dark
          >
            Send App Notification
          </v-btn>
          <v-btn
            @click="SendSMSToOnlineCustomers"
            v-else
            rounded
            color="pink"
            dark
          >
            Send SMS
          </v-btn>
        </div>
      </v-col>
      <v-col cols="12" sm="2"> </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    deliverTO: "",
    message: "",
    title: "",
    MessageType: "SMS",
    DeliverTo: [
      { name: "All Online User", value: "allOnlineUsers" },
      { name: "All Offline User", value: "allOfflineUsers" },
      { name: "All Staff", value: "allStaffs" },
      { name: "All Riders", value: "allRiders" },
    ],
    DeliverTo2: [
      { name: "All Online User", value: "allOnlineUsers" },
      { name: "All Staff", value: "allStaffs" },
      { name: "All Riders", value: "allRiders" },
    ],
  }),
  computed: {
    getToken() {
      return this.$store.getters.getToken;
    },
  },
  methods: {
    SendSMSToOnlineCustomers() {
      if (this.message == "" || this.deliverTO == "") {
        this.$store.commit("setSnackbar", true);
        this.$store.commit(
          "setServerMessage",
          "Fill Out all the required fields"
        );
        return;
      }
      let payload = {
        token: this.getToken,
        message: this.message,
        title: this.title,
        deliverTO: this.deliverTO,
      };
      this.$store.dispatch("sendSMSToOnlineCustomers", payload);
      this.message = "";
      this.title = "";
      this.deliverTO = "";
    },
    SendPushNotification() {
      if (this.message == "" || this.deliverTO == "" || this.title == "") {
        this.$store.commit("setSnackbar", true);
        this.$store.commit(
          "setServerMessage",
          "Fill Out all the required fields"
        );
        return;
      }
      let payload = {
        token: this.getToken,
        message: this.message,
        title: this.title,
        deliverTO: this.deliverTO,
      };
      this.$store.dispatch("SendPushNotification", payload);
      this.message = "";
      this.title = "";
      this.deliverTO = "";
    },
  },
};
</script>